import React from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { graphql } from 'gatsby'
import PageModules from '@Components/home-modules';
const DefaultTemplate = (props) => {
  const [state,setState] = React.useState({
    showMenu:false
  })
  // console.log(props.data.glstrapi)
  const Modules = props.data.glstrapi?.article?.Modules
  const Page = props.data.glstrapi?.article
  const Global = props.data.glstrapi.allStrapiFooter?.edges
  const mapData = props.data.glstrapi.mapViewDashboard
  const contactData = props.data.glstrapi.contact
  const  Right_box = props.data.glstrapi.rightBox

 

    return (
     <Layout  location={props.location} css={Page && Page?.Custom_CSS_Class === 'area-guide-page' ? true : Page && Page?.Custom_CSS_Class === 'our-team-page' ? true : Page && Page?.Custom_CSS_Class === 'blog-page' ? true : Page && Page.Custom_CSS_Class === 'static-page' ? true : false}>
      <SEO title={Page ? Page.Meta_Title : ''} description={Page ? Page.Meta_Description : ''} url={props.location.href}/>

      <Helmet
        bodyAttributes={{
            class: `templates-default-template alias-${Page ? Page.Alias : ''} homepage ${Page ? Page.Custom_CSS_Class : ''}`
        }}
      />
      <PageModules 
        Modules={Modules}
        Page={Page} 
        Global={Global}
        mapData={mapData}
        contactData={contactData}
        Right_box={Right_box}
      />
      
    </Layout>
    )
  
}

export default DefaultTemplate


export const pageQuery = graphql`
  query HomeQuery($id: ID!) {
    glstrapi {
      article(id: $id, publicationState: LIVE) {
        Pagename
        Meta_Description
        Meta_Title
        Custom_CSS_Class
        Layout
        Alias
        Right_box {
          Content
          Right_Title
          Cta_Btn_Block {
            Label
            all_menus {
              Alias
            }
          }
        }
        team {
          id
          Email
          Designation
          Name
          Phone
          URL
          imagetransforms
          Image {
            url
            alternativeText
          }
        }
        Modules {
          ... on GLSTRAPI_ComponentComponentReviewBlock {
            Review_Title
            Show_Review
            Video_Url
            Image {
              alternativeText
              url
            }
          }
          ... on GLSTRAPI_ComponentComponentBanner {
            Banner_CTA_1_Label
            Banner_CTA_2_Label
            Banner_CTA_URL1 {
              Alias
            }
            Banner_CTA_URL2 {
              Alias
            }
            Banner_Content
            Banner_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 1500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
  
            }
            Banner_Title
            Embed_Video_URL
            Right_Video_Image {
              alternativeText
              url
            }
            Show_Review
            Show_Search
            Show_Tags
            Video_Background_Image {
              url
              alternativeText
            }
            Video_Ask
            Slider {
              Slider_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: AUTO
                      width: 1400
                      quality: 75
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )
                  }
                }
    
              }
              Show_Review
            Title
            Banner_Content
            Banner_CTA_2_Label
            Banner_CTA_1_Label
            Banner_CTA_1_URL {
              Alias
            }
            Banner_CTA_2_URL {
              Alias
            }
            }
            Slide
          }
          ... on GLSTRAPI_ComponentComponentTwoColumnBlock {
            CTA_1_Label
            CTA_1_URL {
              Alias
            }
            Content
            Left_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
            Left_Image_Caption
            Right_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 550
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
            Right_Image_Caption
            Title
          }
          ... on GLSTRAPI_ComponentComponentConnectBlock {
            CTA_1_Label
            CTA_2_Label
            Content
            Embed_Video_URL
            Video_Ask
            Image{
              url
              alternativeText
            }
            Ready_To_Start_Title
            CTA_1_URL {
              Alias
            }
            CTA_2_URL {
              Alias
            }
          }
          ... on GLSTRAPI_ComponentComponentStaticVideo {
            Embed_Video_URL
            Style_Type
            Image {
              alternativeText
              url
            }
            Upload_Video {
              url
            }
          }
          ... on GLSTRAPI_ComponentComponentCollections {
            id
            Content
            Select_Collection
            Title
            View_More {
              CTA_1_Label
              CTA_1_URL {
                Alias
              }
            }
          
          }
        }
      }

      mapViewDashboard {
        Latitude
        Longitude
        Percentage
        Property_Change_price
        Property_area_price
        Property_area_text
        Property_change_text
        Property_current_price
        Property_current_text
        Publish
        Sold_count
        Sold_count_text
      }

      rightBox(publicationState: LIVE) {
        Content
        Right_Title
        Cta_Btn_Block_Right {
          Label
          all_menus {
            Alias
          }
        }
      }   
    
    }

  }
`
