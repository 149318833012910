import React, {useRef} from 'react';
/**
 * Components
 */
import Banner from "@Components/Home/Banner/Banner"
// import AboutSection from "@Components/Home/AboutSection/AboutSection"
// import BlockGroup from "@Components/Home/BlockGroup/BlockGroup"
// import CustomersAdvocates from "@Components/Home/CustomersAdvocates/CustomersAdvocates"
// import CustomersAdvocatesStaffs from "@Components/Home/CustomersAdvocatesStaffs/CustomersAdvocatesStaffs"
// import CaseStudies from "@Components/Home/CaseStudies/CaseStudies"
// import ContactBlock from "@Components/Home/ContactBlock/ContactBlock"
// import BreadcrumbsBottom from "@Components/Static/BreadcrumbsBottom/BreadcrumbsHome"

// import GoogleReviews from "@Components/google-reviews/GoogleReviews";
import loadable from "@loadable/component";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "./modules.scss"
import { Helmet } from "react-helmet";
const VideoBlock = loadable(() => import("@Components/Home/VideoBlock/VideoBlock"))
const AboutSection = loadable(() => import("@Components/Home/AboutSection/AboutSection"))
const BlockGroup = loadable(() => import("@Components/Home/BlockGroup/BlockGroup"))
const CustomersAdvocates = loadable(() => import("@Components/Home/CustomersAdvocates/CustomersAdvocates"))
const CustomersAdvocatesStaffs = loadable(() => import("@Components/Home/CustomersAdvocatesStaffs/CustomersAdvocatesStaffs"))
const CaseStudies = loadable(() => import("@Components/Home/CaseStudies/CaseStudies"))
const FeaturedPropery = loadable(() => import("@Components/PropertyDetails/FeaturedPropery/FeaturedPropery"))
const ContactBlock = loadable(() => import("@Components/Home/ContactBlock/ContactBlock"))
const BreadcrumbsBottom = loadable(() => import("@Components/Static/BreadcrumbsBottom/BreadcrumbsHome"))

const Modules = (props) => {
    const myRef = useRef(null)
    const executeScroll = () => {
     myRef.current.scrollIntoView()  
  }
    return (
        <>
            {props.Modules && props.Modules?.length > 0 ? props.Modules.map((Module, i) => {
                return (
                    <div key={i}>
                        {Module.Banner_Title &&
                            <Banner 
                            executeScroll={executeScroll}
                             Pagename={props.Page.Pagename}
                              Title={Module.Banner_Title}
                              Video_Ask={Module.Video_Ask}
                               Banner_Content={Module.Banner_Content}
                                Banner_CTA_1_Label={Module.Banner_CTA_1_Label}
                                 Banner_CTA_1_URL={Module.Banner_CTA_URL1}
                                  Banner_CTA_2_Label={Module.Banner_CTA_2_Label}
                                   Banner_CTA_2_URL={Module.Banner_CTA_URL2}
                                    Banner_Image={Module.Banner_Image}
                                     Right_Video_Image={Module.Right_Video_Image}
                                      Embed_Video_URL={Module.Embed_Video_URL}
                                       Show_Review={Module.Show_Review}
                                        Show_Search={Module.Show_Search}
                                        Slider={Module.Slider}
                                        Slide={Module.Slide}
                                        />
                        }
                        {Module.Title && Module.Left_Image && Module.Right_Image &&
                            <AboutSection
                                Title={Module.Title}
                                Content={Module.Content}
                                CTA_1_Label={Module.CTA_1_Label}
                                CTA_1_URL={Module.CTA_1_URL}
                                Left_Image={Module.Left_Image}
                                Left_Image_Caption={Module.Left_Image_Caption}
                                Right_Image={Module.Right_Image}
                                Right_Image_Caption={Module.Right_Image_Caption}

                            />
                        }
                        {/* {Module.Right_Bar_Title &&
                            <ListBlock Title={Module.Right_Bar_Title} Content={Module.Content} List_Type={Module.List_Type} Points={Module.Bullet_Points} Type={Module.List_Type}  />
                        } */}
                        {/* {Module.Tiles &&
                            <ImagesBlock Tiles={Module.Tiles} />
                        } */}
                        {/* {Module.Add_Blocks &&
                            <BenefitSection Blocks={Module?.Add_Blocks} />
                        } */}
                        {/* {Module.Add_Section_Block && Module.Add_Section_Block?.length > 0 &&
                            <StartMarketing Blocks={Module?.Add_Section_Block} />
                        } */}
                        {/* {Module.Select_Collection === 'Careers' && <>
                           {props.Page.Alias === 'careers' ? <BlockGroupOne /> : ''}
                           <div ref={myRef}></div>
                           <FormBlock Title={Module.Title} Content={Module.Content} Speak_With={Module.Speak_With} />
                           </>

                        } */}
                        {/* {Module.Add_Content &&
                            <ContentPageBlock
                            layout={props.Page?.Layout}
                            Title={props.Page?.Pagename}
                            Meta_Description={props.Page?.Meta_Description}
                            Banner_Title={props.Modules[0]?.Banner_Title}
                            Content={Module?.Add_Content}  
                            Speak_With={props.Page?.team} 
                            Right_Bar={props.Right_box}
                             />
                        } */}
                        {Module.Select_Collection === 'Advocates' &&
                            <div className="d-md-flex flex-md-column">
                                <div className="order-md-2">
                                    {props.Page.Pagename === 'Careers' ?
                                    <CustomersAdvocatesStaffs
                                    Title={Module.Title}
                                    Content={Module.Content}
                                    View_More={Module.View_More}
                                />
                                :
                                <CustomersAdvocates 
                                Title={Module.Title}
                                Content={Module.Content}
                                View_More={Module.View_More}
                            />
                    }
                                </div>
                                {props.Page.Alias === 'home' ?
                                <div className="order-md-1">
                                    <BlockGroup />
                                </div>
                                : ''}
                            </div>
                        }
                        {Module.Style_Type &&
                            <div className="d-none d-md-block">
                                <VideoBlock
                                    Image={Module.Image}
                                    Video={Module.Embed_Video_URL}
                                    Style_Type={Module.Style_Type}
                                    HTMLVideo={Module.Upload_Video}
                                />
                            </div>
                        }
                        <Helmet>
                        <script type="application/ld+json">{`
                                {
                                    "@context": "http://schema.org",
                                    "@type": "VideoObject",
                                    "name": "Manchester Estate Agents",
                                    "description": "Buy, sell and rent property in and around Manchester with our estate agents in Manchester who experts in local market and latest technologies.",
                                    "thumbnailUrl": "https://ggfx-bentleyhurst2.s3.eu-west-2.amazonaws.com/i.dev/BH_Social_Ad_Concept_1_optimized_280421_1_6514f36722.mp4",
                                    "uploadDate": "2021-05-03",
                                    "duration": "9acFe09DPDY",
                                    "embedUrl": "https://www.bentleyhurst.co.uk/",
                                    "regionsAllowed": [{
                                    "@type": "Place",
                                    "name": "UK"
                                    }]
                                }
                            `}
                            </script>
                        </Helmet>

                        {/* {Module.Select_Collection === 'Reviews' &&
                            <Reviews Title={Module.Title} isPage={true}/>
                        } */}
                        {/* {Module.Select_Collection === 'Case_Studies_Insights' &&
                            <RelatedStories
                                Title={Module.Title}
                                Content={Module.Content}
                                collection={Module.Select_Collection}
                                Page="true"
                            />
                        }
                         */}

                        {Module.Select_Collection === 'Insights' &&
                            <CaseStudies 
                                Title={Module.Title}
                                Content={Module.Content}
                                View_More={Module.View_More}
                             />
                        }

                        {Module.Select_Collection === 'SimilarProperty' &&
                            <FeaturedPropery
                                Title={Module.Title}
                                area={'Manchester'}
                            />
                        }
                        {/* {Module.Select_Collection === 'Contact' &&
                            <Contact contactData={props.contactData} />
                        } */}
                        {/* {Module.Select_Collection === 'Contact_Form' &&
                            <ContactFormPage contactData={props.contactData} />
                        } */}
                        {/* {Module.Select_Collection === 'MapView' &&
                            <StaticPropertyMap 
                                mapData={props.mapData}
                            />
                        } */}
                        {/* {Module.Review_Title &&
                            <CustomerReviews 
                                Title={Module.Review_Title}
                                Review={Module.Show_Review}
                                Video_Url={Module.Video_Url}
                                Image={Module.Image}

                            />
                        } */}
                        {Module.Ready_To_Start_Title &&
                            <ContactBlock 
                                Title={Module.Ready_To_Start_Title}
                                Content={Module.Content}
                                CTA_1_Label={Module.CTA_1_Label}
                                CTA_2_Label={Module.CTA_2_Label}
                                CTA_1_URL={Module.CTA_1_URL}
                                CTA_2_URL={Module.CTA_2_URL}
                                Image={Module.Image}
                                Video={Module.Embed_Video_URL}
                                Video_Ask={Module.Video_Ask}
                             />
                        }
                        {/* {Module.Show_Content &&
                            <ContentBlockText 
                            isTeam={Module.Share_Button === true ? true : false}
                                data={Module.Content}                                

                             />
                        } */}
                        {/* {Module.Select_Collection === "Stamp_Duty_Calculator" ?
                            <CalculatorBlock
                            collection={Module.Select_Collection}
                            />
                            : Module.Select_Collection === "Mortgage_Calculator" ?
                            <CalculatorBlock
                            collection={Module.Select_Collection}
                            />
                             : null
                        } */}
                        {/* {Module.Select_Collection === 'Area_Guides' &&
                            <ExploreManchester 
                                collection={Module.Select_Collection}
                                Title={Module.Title}
                                Content={Module.Content}
                                 /> 
                        } */}
                        {/* {Module.Select_Collection === 'Our_Teams' &&
                            <ExploreManchester 
                            collection={Module.Select_Collection}
                            Title={Module.Title}
                            Content={Module.Content}
                             />
                        } */}

                    </div>
                )
            }) : ''}
            {/* {props.Page.Similar_Guides_and_Insight &&
                <RelatedStories Title={'Case Studies & Insights'} GuideandInsights="true"  />
            }
            {props.Page.Youtube_Subscribe &&
                <SubscribeBlock />
            }    */}
            {/* <BreadcrumbsBottom Page={props.Page.Pagename} alias={props.Page.Alias} /> */}
        </>
    )
}
export default Modules
